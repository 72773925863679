import React, {useEffect, useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector} from 'react-redux';
import { useYupValidationResolver } from "helpers/yupValidationResolver";

import * as Yup from "yup";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Select from "react-select";
// import AmlIcon from '../../../assets/images/aml.svg';
import CheckboxSelect from '../../../assets/images/checkboxSelect.svg';
import CheckboxFill from '../../../assets/images/checkboxFill.svg';
import CheckboxHallow from '../../../assets/images/checkboxHallow.svg';
import { getCredentials } from 'redux/actions/disbursement';
import { LoadingTableContent } from 'components/loader/Loader';
import EmptyState from 'components/emptyState/EmptyState';
import TablePagination from 'shared/tablePagination/tablePagination';

const SourceInfo = ({setCurrentStep, countries=[], currency=[], payload, setPayload, currentStep}) => {
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const dispatch = useDispatch();

    const { sourceInstitutionLoading, sourceInstitutionPayload } = useSelector(({ disbursement }) => {
        return {
          sourceInstitutionError: disbursement?.sourceInstitutionError,
          sourceInstitutionPayload: disbursement?.sourceInstitutionSuccess,
          sourceInstitutionLoading: disbursement?.sourceInstitutionLoading,
         };
      });
     

    const validationSchema = Yup.object({
        applicationName: Yup.string().required("This field is required"),
      });
      const resolver = useYupValidationResolver(validationSchema);
      const {
        register,
        formState: { errors },
        control,
      } = useForm({ resolver });

      const onSelectSource = (val, i) =>{
          setSelected([i]); 
          setPayload({...payload,sourceData: val, sourceCurrency: {label: val?.currencyCode, value: val?.currencyCode}, sourceCountry: {label: val?.countryName, value: val?.countryCode}})
          dispatch(getCredentials(val?.gatewayCode))
      }

      const fetchList = (curren, coun) => { 
          if(coun?.label){
            setPayload({...payload, sourceCurrency: null, sourceCountry: coun})
            const items = sourceInstitutionPayload?.data?.responseData?.items?.filter(val => val?.countryName?.toLowerCase() === coun?.label?.toLowerCase())
            setAllData(items)
            setData(items.slice(((currentPage-1)*10), ((currentPage*10))))
            setCurrentPage(1)
          }else if(curren) {
            if(payload?.sourceCountry){
              const items = sourceInstitutionPayload?.data?.responseData?.items?.filter(val => val?.currencyCode?.toLowerCase() === curren?.toLowerCase() && val?.countryName?.toLowerCase() === payload?.sourceCountry?.label?.toLowerCase())
              setAllData(items)
              setData(items.slice(((currentPage-1)*10), ((currentPage*10))))
            }else{
              const items = sourceInstitutionPayload?.data?.responseData?.items?.filter(val => val?.currencyCode?.toLowerCase() === curren?.toLowerCase())
              setAllData(items)
              setData(items.slice(((currentPage-1)*10), ((currentPage*10))))
            }
          }
      }

    useEffect(()=>{
      setData(allData.slice(((currentPage-1)*10), ((currentPage*10))))
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);


    useEffect(()=>{
       if(!sourceInstitutionLoading && sourceInstitutionPayload?.data?.responseData?.items?.length) {
        setData(sourceInstitutionPayload?.data?.responseData?.items.slice(currentPage, ((currentPage*10)+10)));
        setAllData([...sourceInstitutionPayload?.data?.responseData?.items])
       }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceInstitutionLoading]);

    return (
        <>
            <div className="form-content">
                        <div className="choose">Choose your country and currency</div>
                    
                        <form>
                            <Row>
                                <Col>
                        <label htmlFor="Country" className="form-label">Source Country</label>
                        <Controller
                            control={control}
                            name="status"
                            id="Country"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={value||payload?.sourceCountry}
                                id="Country"
                                onChange={e => {fetchList(null,  e)}}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [...countries?.map(val=>{
                                        return {label: val?.name, value: val?.isO3}
                                    })]
                                }
                            />
                            )}
                        />
                        </Col>
                        <Col>
                        <label htmlFor="Currency" className="form-label">Source Currency</label>
                        <Controller
                            control={control}
                            name="Currency"
                            id="Currency"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.sourceCurrency}
                                onChange={e => {fetchList(e?.label, null); setPayload({...payload, sourceCurrency: e})}}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                        [...currency?.map(val=>{
                                            return {label: val?.currencyCode, value: val?.numericCode}
                                        })]
                                 }
                            />
                            )}
                        />
                        </Col>
                        </Row>
                        <div className="aml-content"><input type="checkbox" id="aml" checked={payload?.aml} onChange={{...payload, aml: payload?.aml?true:false}}/> <label for="aml" className="aml">
                          Use AML 
                          {/* <img src={AmlIcon} alt="aml"/> */}
                          </label></div>
                        </form>
                    </div>
                       <div className="table-responsiveness">
                          {allData?.length ?<div className="title-content">
                            <div className="choose">Select a source institution</div>
                         </div>:<br/>}
                        {sourceInstitutionLoading || allData?.length? <table className="table">
                            <thead>
                                <tr>
                                    <td><img src={CheckboxSelect} alt="checkbox"/>  Source Institution</td>
                                    <td>Gateway Fee  (₦)</td>
                                    <td>Platform Fee</td>
                                </tr>
                            </thead>
                            <tbody>
                                {sourceInstitutionLoading?<LoadingTableContent colSpan={3}/>:null}
                                {data?.map((val, i) => {
                                    return <tr onClick={()=>{onSelectSource(val,i)}}>
                                    <td>
                                        {payload?.sourceData?.id === val?.id || selected.indexOf(i)>-1?<img src={CheckboxFill} alt="checkbox"/>:<img src={CheckboxHallow} alt="checkbox"/>}         
                                          {val?.gatewayName}</td>
                                    <td>{val?.gatewayFee}</td>
                                    <td>{val?.platformFee}</td>
                                </tr>
                                })}
                            </tbody>
                        </table>:null}
                        {allData?.length ? <TablePagination
                    totalCount={allData?.length}
                    currentPage={currentPage}
                    pageSize={10}
                    onPageChange={(page) => setCurrentPage(page)}
                    totalPages={Math.ceil(allData?.length/10)}
                  />: null}
                        {!sourceInstitutionLoading && !allData?.length ? <EmptyState message="Choose another country or currency"/>: null}
                    </div>
                    {allData?.length?<div className="btns">
                  <Button
                    label="Previous"
                    size="sm"
                    className="cancel-btn"
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                  <Button label="Proceed" disabled={!payload?.sourceData && selected?.length === 0} className="proceed-btn" onClick={() => setCurrentStep(3)} />
                </div>:null}
        </>
    )
}
export default SourceInfo;