import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from 'reactstrap';
import { useYupValidationResolver } from "helpers/yupValidationResolver";

import * as Yup from "yup";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Select from "react-select";
// import AmlIcon from '../../../assets/images/aml.svg';

const Overdraft = ({setCurrentStep, countries=[], currency=[], payload, setPayload}) => {
    const validationSchema = Yup.object({
        applicationName: Yup.string().required("This field is required"),
      });
      const resolver = useYupValidationResolver(validationSchema);
      const {
        register,
        formState: { errors },
        control,
      } = useForm({ resolver });

    return (
        <>
            <div className="form-content">
                        <div className="choose">Set your overdraft limit</div>
                    
                        <form>
                            <Row>
                            <Col xs="12" sm="12" md="8">
                        <label htmlFor="Country" className="form-label">Source Country</label>
                        <Controller
                            control={control}
                            name="status"
                            id="Country"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.sourceCountry}
                                id="Country"
                                onChange={e => {setPayload({...payload, sourceCountry: e})}}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [...countries?.map(val=>{
                                        return {label: val?.name, value: val?.isO3}
                                    })]
                                }
                            />
                            )}
                        />
                        </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="8">
                                    <label htmlFor="Currency" className="form-label">Source Currency</label>
                                    <Controller
                                        control={control}
                                        name="Currency"
                                        id="Currency"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            {...register("status", { required: true })}
                                            value={value||payload?.sourceCurrency}
                                            onChange={e => {setPayload({...payload, sourceCurrency: e})}}
                                            onBlur={onBlur}
                                            inputRef={ref}
                                            controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                            controlErrorMsg={errors?.status?.message}
                                            components={{
                                            IndicatorSeparator: () => null,
                                            Control: SelectControl,
                                            }}
                                            options={ 
                                                    [...currency?.map(val=>{
                                                        return {label: val?.currencyCode, value: val?.numericCode}
                                                    })]
                                            }
                                        />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="8">
                                    <label htmlFor="Currency" className="form-label">Repayment Cycle</label>
                                    <Controller
                                        control={control}
                                        name="RepaymentCycle"
                                        id="RepaymentCycle"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            {...register("status", { required: true })}
                                            value={payload?.amount}
                                            onChange={e => {setPayload({...payload, RepaymentCycle: e.value})}}
                                            onBlur={onBlur}
                                            inputRef={ref}
                                            controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                            controlErrorMsg={errors?.status?.message}
                                            components={{
                                            IndicatorSeparator: () => null,
                                            Control: SelectControl,
                                            }}
                                            options={ 
                                                    [{label: 'Daily', value: 1}, {label: 'Weekly', value: 2}, {label: 'Bi_Weekly', value: 3}, {label: 'Monthly', value: 4}, {label: 'Quartely', value: 5}]
                                            }
                                        />
                                        )}
                                    />


                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="8">
                                    <label htmlFor="Currency" className="form-label">Overdraft Limit</label>
                                    <input type="number" value={payload?.OverdraftLimit} id="name" onChange={e => {setPayload({...payload, OverdraftLimit: e.target.value})}} className="form-control"/>
                                </Col>
                            </Row>
                       
                       
                        <div className="aml-content"><input type="checkbox" id="aml" checked={payload?.aml} onChange={{...payload, aml: payload?.aml?true:false}}/> <label for="aml" className="aml">Use AML
                         {/* <img src={AmlIcon} alt="aml"/> */}
                         </label></div>
                        </form>
                    </div>
                    <div className="btns">
                  <Button
                    label="Previous"
                    size="sm"
                    className="cancel-btn"
                    onClick={() => setCurrentStep(1)}
                  />
                  <Button label="Proceed" disabled={!payload?.sourceCurrency || !payload?.sourceCurrency || !payload?.OverdraftLimit } className="proceed-btn" onClick={() => setCurrentStep(3)} />
                </div>
        </>
    )
}
export default Overdraft;