import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import URLS from "helpers/urls";
import Onboarding from "pages/disbursement-onboarding/Onboarding";
import DisbursementList from "pages/disbursementList/DisbursementList";
import Notification from "shared/notifications/notifications";

const App = ({ url }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      const paths = url?.split("/disbursement-service");
      if (paths?.length > 1) {
        // history.push(paths?.join(''))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div style={{ minHeight: "50vh" }} className={`container-fluid`}>
      <Notification />
      <Switch>
        <Route exact path={URLS.Disbursement} component={Onboarding} />
        <Route path={URLS.List} exact component={DisbursementList} />
        <Route path={URLS.Home} component={DisbursementList} />
      </Switch>
    </div>
  );
};

export default App;
