import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./ToggleStatusModal.scss";

const ToggleStatusModal = ({ show, toggleModal, onSubmit, status }) => {
  return (
    <div className="deactivate-modal-container">
      <Modal isOpen={show} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal} className="font-weight-bold title">
          {status ? "Deactivate" : "Activate"} Instance
        </ModalHeader>
        <ModalBody className="modal__body">
          <p className="mb-0">
            Are you sure about this, you could lose some information
          </p>
        </ModalBody>
        <ModalFooter>
          <span
            data-testid="cancel-btn"
            onClick={toggleModal}
            className="px-3 cancel-btn"
          >
            Cancel
          </span>
          <Button
            color={status ? "danger" : "primary"}
            onClick={onSubmit}
            className="submit-btn"
          >
            {status ? "Deactivate" : "Activate"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ToggleStatusModal;
