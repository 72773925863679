import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { useYupValidationResolver } from "helpers/yupValidationResolver";

import * as Yup from "yup";
import SelectControl from "components/selectControl/SelectControl";
import Select from "react-select";
import Button from "vgg-ui-react/dist/components/Core/Button";

const Credentials = ({setCurrentStep, currentStep, currency=[], setPayload, payload}) => {
  console.log({payload})
  const [result, setResult] = useState({})
  const { getCredentialsPayload } = useSelector(({ disbursement }) => {
    return {
      getCredentialsError: disbursement?.getCredentialsError,
      getCredentialsPayload: disbursement?.getCredentialsSuccess,
      getCredentialsLoading: disbursement?.getCredentialsLoading,
     };
  });

  const validationSchema = Yup.object({
    applicationName: Yup.string().required("This field is required"),
  });
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    formState: { errors },
    control,
  } = useForm({ resolver });


      const onSubmit = data => {
        setPayload({...payload, credentials: result})
      };
    
    return (
        <>
            <div className="form-content">
            {payload?.sourceData?.gatewayCode === "AFRICAONE"?<>
               <div className="choose">Select a wallet to disburse from</div>
               <Row>
               <Col md="7">
                        <label htmlFor="Currency" className="form-label">Africa One Wallet ID</label>
                        <Controller
                            control={control}
                            name="Currency"
                            id="Currency"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.walletId}
                                onChange={e => {setPayload({...payload, walletId: e})}}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                        [...currency?.map(val=>{
                                            return {label: val?.currencyCode, value: val?.numericCode}
                                        })]
                                 }
                            />
                            )}
                        />
                        </Col>
               </Row>
            </>:<>
                        <div className="choose">Institutional Credentials</div>
                    
                        <form>
                          {Object?.keys(payload?.credentials||{})?.length > 0 ? Object?.keys(payload?.credentials||{})?.map(val=>{
                              return <Row key={val}>
                                        <Col md="5">
                                        <label htmlFor={val} className="form-label">{val}</label>
                                        <div>
                                          <input type={val?.toLowerCase() === 'password'?"password":"text"}  id={val} onChange={e=>{setResult({...result, [val]: payload?.credentials[val]})}} className="form-control"/>
                                        </div>
                                        </Col>
                                      </Row>
                        }):getCredentialsPayload?.data?.responseData?.map(val=>{
                              return <Row key={val?.id}>
                                        <Col md="5">
                                        <label htmlFor={val?.key} className="form-label">{val?.key}</label>
                                        <div>
                                          <input type={val?.key?.toLowerCase() === 'password'?"password":"text"}  id={val?.key} onChange={e=>{setResult({...result, [val?.key]: e.target.value})}} className="form-control"/>
                                        </div>
                                        </Col>
                                      </Row>
                        })}
                        </form>
                        </>}
                        </div>
                    
                    <div className="btns">
                  <Button
                    label="Previous"
                    size="sm"
                    className="cancel-btn"
                    onClick={() => setCurrentStep(currentStep-1)}
                  />
                  <Button label="Next" disabled={payload?.sourceData?.gatewayCode === "AFRICAONE" && !payload?.walletId} className="proceed-btn" onClick={() => {onSubmit();setCurrentStep(4)}} />
                </div>
        </>
    )
}
export default Credentials;