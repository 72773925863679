import React from "react";
import noData from "assets/images/noData.svg";
import { useHistory } from "react-router";
import "./emptyState.scss";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import URLS from "helpers/urls";

const EmptyState = ({ message }) => {
  const history = useHistory();
  return (
    <div className="empty-container">
      <div>
        <div className="sp-mb--24">
          <img src={noData} alt="empty" />
        </div>
        <div className="text-center sp-mb--16">
          <div className="sp-mb--8">
            <Typography
              typographyVariant="subtitle1"
              text="Nothing here"
              className="font-weight-bold"
            />
          </div>
          <div>
            <Typography
              typographyVariant="caption"
              text={message || "Add a service to configure options"}
              className="sp-text-color--neutral-400"
            />
          </div>
        </div>
        {!message ? (
          <div className="text-center">
            <Button
              label="Configure"
              background="blue"
              onClick={() => history.push(URLS.Disbursement)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EmptyState;
