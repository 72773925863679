import { Types } from "../actions/disbursement";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {
  data: [],
  loadingData: false,
  dataError: null,

  walletHistory: [],
  loadingWalletHistory: false,
  walletHistoryError: null,

  accounts: [],
  loadingAccounts: false,
  accountsError: null,
};

const walletInstanceReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(Types.GET_CREDENTIALS):
      return {
        ...state,
        getCredentialsSuccess: null,
        getCredentialsError: null,
        getCredentialsLoading: true,
      };
    case SUCCESS(Types.GET_CREDENTIALS):
      return {
        ...state,
        getCredentialsLoading: false,
        getCredentialsSuccess: actions.payload,
        getCredentialsError: null,
      };
    case FAILURE(Types.GET_CREDENTIALS):
      return {
        ...state,
        getCredentialsLoading: false,
        getCredentialsSuccess: null,
        getCredentialsError: actions.payload,
      };
    case REQUEST(Types.SUBMIT_DISBURSEMENT):
      return {
        ...state,
        submitDisbursementSuccess: null,
        submitDisbursementError: null,
        submitDisbursementLoading: true,
      };
    case SUCCESS(Types.SUBMIT_DISBURSEMENT):
      return {
        ...state,
        submitDisbursementLoading: false,
        submitDisbursementSuccess: actions.payload,
        submitDisbursementError: null,
      };
    case FAILURE(Types.SUBMIT_DISBURSEMENT):
      return {
        ...state,
        submitDisbursementLoading: false,
        submitDisbursementSuccess: null,
        submitDisbursementError: actions.payload,
      };
    case REQUEST(Types.GET_COUNTRY):
      return {
        ...state,
        getCountrySuccess: null,
        getCountryError: null,
        getCountryLoading: true,
      };
    case SUCCESS(Types.GET_COUNTRY):
      return {
        ...state,
        getCountryLoading: false,
        getCountrySuccess: actions.payload,
        getCountryError: null,
      };
    case FAILURE(Types.GET_COUNTRY):
      return {
        ...state,
        getCountryLoading: false,
        getCountrySuccess: null,
        getCountryError: actions.payload,
      };
    case REQUEST(Types.GET_CURRENCY):
      return {
        ...state,
        getCurrencySuccess: null,
        getCurrencyError: null,
        getCurrencyloading: true,
      };
    case SUCCESS(Types.GET_CURRENCY):
      return {
        ...state,
        getCurrencyloading: false,
        getCurrencySuccess: actions.payload,
        getCurrencyError: null,
      };
    case FAILURE(Types.GET_CURRENCY):
      return {
        ...state,
        getCurrencyloading: false,
        getCurrencySuccess: null,
        getCurrencyError: actions.payload,
      };
    case REQUEST(Types.SOURCE_INSTITUTION):
      return {
        ...state,
        sourceInstitutionSuccess: null,
        sourceInstitutionError: null,
        sourceInstitutionLoading: true,
      };
    case SUCCESS(Types.SOURCE_INSTITUTION):
      return {
        ...state,
        sourceInstitutionLoading: false,
        sourceInstitutionSuccess: actions.payload,
        sourceInstitutionError: null,
      };
    case FAILURE(Types.SOURCE_INSTITUTION):
      return {
        ...state,
        sourceInstitutionLoading: false,
        sourceInstitutionSuccess: null,
        sourceInstitutionError: actions.payload,
      };
    case REQUEST(Types.DESTINATION_INSTITUTION):
      return {
        ...state,
        destinationInstitutionSuccess: null,
        destinationInstitutionError: null,
        destinationInstitutionLoading: true,
      };
    case SUCCESS(Types.DESTINATION_INSTITUTION):
      return {
        ...state,
        destinationInstitutionLoading: false,
        destinationInstitutionSuccess: actions.payload,
        destinationInstitutionError: null,
      };
    case FAILURE(Types.DESTINATION_INSTITUTION):
      return {
        ...state,
        destinationInstitutionLoading: false,
        destinationInstitutionSuccess: null,
        destinationInstitutionError: actions.payload,
      };
    case REQUEST(Types.GET_INSTANCES):
      return {
        ...state,
        getInstanceLoading: true,
        getInstanceError: null,
        getInstanceSuccess: null,
      };
    case SUCCESS(Types.GET_INSTANCES):
      return {
        ...state,
        getInstanceError: null,
        getInstanceLoading: false,
        getInstanceSuccess: actions.payload.data.responseData,
      };
    case FAILURE(Types.GET_INSTANCES):
      return {
        ...state,
        getInstanceLoading: false,
        getInstanceSuccess: null,
        getInstanceError: actions.payload,
      };
    case REQUEST(Types.TOGGLE_ISENABLED):
      return {
        ...state,
        toggleLoading: true,
        toggleError: null,
        toggleSuccess: null,
      };
    case SUCCESS(Types.TOGGLE_ISENABLED):
      return {
        ...state,
        toggleLoading: false,
        toggleSuccess: actions.payload,
        toggleError: null,
      };
    case FAILURE(Types.TOGGLE_ISENABLED):
      return {
        ...state,
        toggleLoading: false,
        toggleError: true,
        toggleSuccess: null,
      };
    case REQUEST(Types.GET_FILTERS):
      return {
        ...state,
        getFiltersLoading: true,
        getFiltersSuccess: null,
        getFiltersError: null,
      };
    case SUCCESS(Types.GET_FILTERS):
      return {
        ...state,
        getFiltersLoading: false,
        getFiltersSuccess: actions.payload?.data?.responseData,
        getFiltersError: null,
      };
    case FAILURE(Types.GET_FILTERS):
      return {
        ...state,
        getFiltersLoading: false,
        getFiltersSuccess: null,
        getFiltersError: true,
      };
    default:
      return { ...state };
  }
};

export default walletInstanceReducer;
