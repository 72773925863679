import React, { useEffect, useState } from "react";
import Button from "vgg-ui-react/dist/components/Core/Button";
import {
  Button as ReactstrapBtn,
  Form,
  Input,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import "./FilterDropdown.scss";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "redux/actions/disbursement";

const FilterDropdown = ({ onSubmit, dropdownOpen, toggleDropdown }) => {
  const [status, setStatus] = useState(false);
  // const [preFunding, setPreFunding] = useState(false);
  // const [postFunding, setPostFunding] = useState(false);
  const [destination, setDestination] = useState("");
  const [source, setSource] = useState("");
  const dispatch = useDispatch();

  const { getFiltersSuccess } = useSelector(({ disbursement }) => {
    return {
      getFiltersError: disbursement?.getFiltersError,
      getFiltersLoading: disbursement?.getFiltersLoading,
      getFiltersSuccess: disbursement?.getFiltersSuccess,
    };
  });

  useEffect(() => {
    dispatch(getFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const _status = JSON.parse(status);
    onSubmit({ _status, destination, source });
  };

  return (
    <div className="custom-dropdown-container">
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle className="p-0 border-0 me-2 bg-transparent">
          <Button
            label={
              <span className="d-flex align-items-center">
                <i className="ri-filter-3-line me-2"></i>
                <span>Filter</span>
                <i className="ri-arrow-down-s-line ms-2"></i>
              </span>
            }
            background="neutral"
            size="md"
            style={{ background: "#eee", color: "#7E7E7E" }}
            onClick={toggleDropdown}
          />
        </DropdownToggle>
        <DropdownMenu right className="filter-dropdown mt-2">
          <div className="card-body">
            <Form onSubmit={(e) => handleSubmit(e)}>
              {/* <div className="d-flex mb-3">
                <FormGroup check className="me-3">
                  <Input
                    type="checkbox"
                    data-testid="filter-input"
                    onChange={(e) => setPostFunding(e.target.value)}
                    id="postFunding"
                  />{" "}
                  <Label check htmlFor="postFunding">
                    Post-funding
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    data-testid="filter-input"
                    id="preFunding"
                    onChange={(e) => setPreFunding(e.target.value)}
                  />{" "}
                  <Label htmlFor="preFunding" check>
                    Pre-funding
                  </Label>
                </FormGroup>
              </div> */}
              <FormGroup className="mb-3">
                <Label htmlFor="userStatus">Status</Label>
                <Input
                  id="status"
                  type="select"
                  data-testid="filter-input"
                  name="userStatus"
                  onChange={(e) => setStatus(e.target.value)}>
                  <option selected disabled>
                    Choose an option
                  </option>
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </Input>
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="userSource">Source</Label>
                <Input
                  id="source"
                  type="select"
                  data-testid="filter-input"
                  name="userSource"
                  onChange={(e) => setSource(e.target.value)}>
                  <option selected disabled>
                    Choose an option
                  </option>
                  {getFiltersSuccess?.sourceName?.map((source, index) => (
                    <option key={index} value={source}>
                      {source}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="mb-4">
                <Label for="destination">Destination</Label>
                <Input
                  type="select"
                  data-testid="filter-input"
                  id="destination"
                  name="userDestination"
                  onChange={(e) => setDestination(e.target.value)}>
                  <option selected disabled>
                    Choose an option
                  </option>
                  {getFiltersSuccess?.destinationName?.map(
                    (destination, index) => (
                      <option key={index} value={destination}>
                        {destination}
                      </option>
                    )
                  )}
                </Input>
              </FormGroup>
              <ReactstrapBtn type="submit" color="primary" className="w-100">
                Save
              </ReactstrapBtn>
            </Form>
          </div>
        </DropdownMenu>
      </Dropdown>
      {/* <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Dropdown button
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          
        </ul>
      </div> */}
    </div>
  );
};

export default FilterDropdown;
