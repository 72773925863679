import React, {useEffect, useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Spinner } from 'reactstrap';
import {useHistory} from 'react-router-dom';
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from "yup";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Select from "react-select";
import { submitDisbursement } from 'redux/actions/disbursement';
import URLS from 'helpers/urls';
import { errorMessage, successMessage } from 'redux/actions/notification';

const Review = ({setCurrentStep, currentStep, payload}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [submitStatus, setSubmitStatus] = useState(false);

    const { submitDisbursementPayload, submitDisbursementLoading } = useSelector(({ disbursement }) => {
        return {
          submitDisbursementError: disbursement?.submitDisbursementError,
          submitDisbursementPayload: disbursement?.submitDisbursementSuccess,
          submitDisbursementLoading: disbursement?.submitDisbursementLoading,
          getCredentialsError: disbursement?.getCredentialsError,
          getCredentialsPayload: disbursement?.getCredentialsSuccess,
          getCredentialsLoading: disbursement?.getCredentialsLoading,
        };
      });
      
    const validationSchema = Yup.object({
        applicationName: Yup.string().required("This field is required"),
      });
      const resolver = useYupValidationResolver(validationSchema);
      const {
        register,
        handleSubmit,
        formState: { errors },
        control,
      } = useForm({ resolver });

      const onSubmit = data => {
        console.table(data);
      };

      console.log({payload})
      const submitReview = () => {
        const result = {
            "name": payload?.name,
            "useAml": payload?.aml,
            "fundingType": payload['OverdraftLimit']?2:1,
            "useDefaultCredentials": true,
            "sourceInformation": {
              "sourceAccountNumber": null,
              "sourceAccountName": null,
              "sourceGatewayCode": payload?.sourceData?.gatewayCode,
              "sourceCountry": payload?.sourceCountry?.label,
              "sourceCurrency": payload?.sourceData?.gatewayCode === "AFRICAONE"?payload?.walletId?.label:payload?.sourceCurrency?.label,
              "credentials": payload?.credentials
            },
            "destinationInformation": {
              "destinationCountry": payload?.destinationCountry?.label,
              "destinationGatewayCode": payload?.destinationData?.gatewayCode,
              "destinationCurrency": payload?.destinationCurrency?.label,
              "useDefaultDestination": true
            },
            "isActive": true
          }
          if(payload?.OverdraftLimit && payload?.RepaymentCycle) {
            result['OverdraftLimit'] = payload['OverdraftLimit'];
            result["RepaymentCycle"] = payload['RepaymentCycle']
          }
          dispatch(submitDisbursement(result));
          setSubmitStatus(true);
      }

      useEffect(()=>{ 
          if(submitDisbursementPayload?.data?.requestSuccessful && !submitDisbursementLoading && submitStatus) {
             history.push(URLS.List);
             setSubmitStatus(false);
             dispatch(successMessage("Disbursment was created successfully"));
          }else if(!submitDisbursementPayload?.data?.requestSuccessful && submitDisbursementPayload?.data?.message && submitStatus){
            setSubmitStatus(false);
            dispatch(errorMessage(submitDisbursementPayload?.data?.message));
          }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [submitDisbursementLoading])

    return (
        <>
            <div className="form-content">
                        <div className="choose">Let’s review what we have</div>
                    
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                               {payload?.sourceCountry?<Col>
                        <label htmlFor="status" className="form-label">Source Country</label>
                        <Controller
                            control={control}
                            name="status"
                            id="status"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.sourceCountry}
                                onChange={e => onChange({ target: { value: e } })}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [payload?.sourceCountry]
                                }
                            />
                            )}
                        />
                        </Col>:null}
                                <Col>
                        <label htmlFor="status" className="form-label">Source Currency</label>
                        <Controller
                            control={control}
                            name="status"
                            id="status"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.sourceCurrency}
                                onChange={e => onChange({ target: { value: e } })}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [payload?.sourceCurrency]
                                }
                            />
                            )}
                        />
                        </Col>
                            </Row>
                            <Row>
                                <Col>
                        <label htmlFor="status" className="form-label">Destination Country</label>
                        <Controller
                            control={control}
                            name="status"
                            id="status"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.destinationCountry}
                                onChange={e => onChange({ target: { value: e } })}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [payload?.destinationCountry]
                                }
                            />
                            )}
                        />
                        </Col>
                        <Col>
                        <label htmlFor="status" className="form-label">Destination Currency</label>
                        <Controller
                            control={control}
                            name="status"
                            id="status"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.destinationCurrency}
                                onChange={e => onChange({ target: { value: e } })}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [{ label: "NGN", value: 1 }]
                                }
                            />
                            )}
                        />
                        </Col>
                        </Row>
                        </form>
                    </div>
                    {payload?.sourceData?.gatewayName?<div className="table-responsiveness">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>Source Institution</td>
                                    {/* <td>Gateway Fee  (₦)</td> */}
                                    <td>Gateway Fee</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                          {payload?.sourceData?.gatewayName}</td>
                                    <td>{payload?.sourceData?.gatewayFee}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>:null}
                    <div className="table-responsiveness mt-5">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>Destination Institution</td>
                                    <td>Settlement Time</td>
                                    {/* <td>Unique Code</td> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {payload?.destinationData?.institutionName}</td>
                                    {/* <td>{payload?.destinationData?.amount}</td> */}
                                    <td>{payload?.destinationData?.sla}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="btns">
                  <Button
                    label="Previous"
                    size="sm"
                    className="cancel-btn"
                    onClick={() => setCurrentStep(currentStep-1)}
                  />
                  <Button label={submitDisbursementLoading?<Spinner size="sm" as="span" tag="span" color="white"/>:`Finish`} disabled={submitDisbursementLoading} loading={submitDisbursementLoading} className="proceed-btn" onClick={() => submitReview()} />
                </div>
        </>
    )
}
export default Review
;