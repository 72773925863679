import React from "react";
import "./switch.scss";

const Switch = ({ id, name, disabled, isChecked, onChange }) => {
  return (
    <>
      <div className="form-check form-switch">
        <input
          className="form-check-input switch_input"
          type="checkbox"
          role="switch"
          checked={isChecked}
          id={id}
          name={name}
          onChange={onChange}
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={id}></label>
      </div>
    </>
  );
};

export default Switch;
