import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "vgg-ui-react/dist/components/Core/Table";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "reactstrap";
import TableActionDropdown from "components/tableActionDropdown/tableActionDropdown";
import Switch from "components/switch/Switch";
import { Elipses } from "components/svgs";
import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "./disbursementList.scss";
import ToggleStatusModal from "./toggleStatusModal/ToggleStatusModal";
import FilterDropdown from "components/filterDropdown/FilterDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getAllInstances, toggleIsEnabled } from "redux/actions/disbursement";
import URLS from "helpers/urls";
import TablePagination from "shared/tablePagination/tablePagination";

const DisbursementList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [activeRow, setActiveRow] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);

  const {
    getInstanceLoading,
    getInstanceSuccess,
    toggleLoading,
    toggleSuccess,
  } = useSelector(({ disbursement }) => {
    return {
      getInstanceLoading: disbursement?.getInstanceLoading,
      getInstanceError: disbursement?.getInstanceError,
      getInstanceSuccess: disbursement?.getInstanceSuccess,
      toggleLoading: disbursement?.toggleLoading,
      toggleSuccess: disbursement?.toggleSuccess,
    };
  });

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    dispatch(
      getAllInstances(pageSize, currentPage, false, null, null, null, false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  useEffect(() => {
    toggleSuccess &&
      dispatch(
        getAllInstances(pageSize, currentPage, false, null, null, null, false)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleSuccess]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        getAllInstances(
          pageSize,
          currentPage,
          false,
          null,
          null,
          searchValue,
          false
        )
      );
    }, 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const toggleStatusModal = () => {
    showStatusChangeModal
      ? setShowStatusChangeModal(false)
      : setShowStatusChangeModal(true);
  };
  const toggleStatus = () => {
    const payload = {
      instanceId: activeRow.id,
    };
    dispatch(toggleIsEnabled(payload));
    toggleStatusModal();
  };
  const toggleFilterDropdown = () => {
    showFilterDropdown
      ? setShowFilterDropdown(false)
      : setShowFilterDropdown(true);
  };

  const applyFilter = ({ _status, destination, source }) => {
    dispatch(
      getAllInstances(
        pageSize,
        currentPage,
        _status,
        source,
        destination,
        null,
        false
      )
    );
  };

  // const onViewConfiguration = (event, rowData) => {
  //stuff should happen on view disbursement list
  // };

  // const tableActions = [
  //   {
  //     id: 1,
  //     method: onViewConfiguration,
  //     name: "View",
  //   },
  //   // {
  //   //   id: 2,
  //   //   method: onDuplicate,
  //   //   name: "Duplicate",
  //   // },
  // ];

  return (
    <>
      <div className="disbursement-instance-container">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="search-container">
            <input
              type="search"
              placeholder="search"
              className="form-control border-0"
              data-testid="search-input"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <i className="ri-search-line"> </i>
          </div>
          <div className="d-flex align-items-center">
            <FilterDropdown
              dropdownOpen={showFilterDropdown}
              onSubmit={applyFilter}
              toggleDropdown={toggleFilterDropdown}
            />
            <Button
              label="Configure"
              background="blue"
              size="md"
              onClick={() => history.push(URLS.Disbursement)}
            />
          </div>
        </div>
        <>
          <>
            <Table cellVariants="default">
              <thead>
                <tr>
                  <th width="15%">ID</th>
                  <th width="15%">Name</th>
                  <th width="15%">Fund Type</th>
                  <th width="25%">Source</th>
                  <th width="15%">Destination</th>
                  <th width="15%">Status</th>
                  <th width="15%">Actions</th>
                </tr>
              </thead>
              {getInstanceLoading ? (
                <tbody style={{ borderTop: "none" }}>
                  <LoadingTableContent colSpan={7} />
                </tbody>
              ) : (
                <>
                  {getInstanceSuccess?.items?.length > 0 ? (
                    <tbody style={{ borderTop: "none" }}>
                      {getInstanceSuccess?.items?.map((element, index) => {
                        return (
                          <tr key={element.id}>
                            <td
                              className="copy-id"
                              href="#"
                              id={`id${index}`}
                              onMouseEnter={() => setTooltipId(`id${index}`)}>
                              <CopyToClipboard
                                text={element?.id}
                                onCopy={() => {}}>
                                <span>{element?.id?.slice(0, 8)}***</span>
                              </CopyToClipboard>
                            </td>
                            {tooltipId === `id${index}` && (
                              <Tooltip
                                placement="right"
                                isOpen={tooltipOpen}
                                target={`id${index}`}
                                toggle={toggle}>
                                Click to copy {element?.id}
                              </Tooltip>
                            )}
                            <td>{element.name}</td>
                            <td>{element.fundingType}</td>
                            <td>{element.sourceName}</td>
                            <td>{element.destinationName}</td>
                            <td>
                              {element.status
                                ? Elipses("#3BA757")
                                : Elipses("#FC4646")}
                              &nbsp;&nbsp;
                              {element.status ? "Enabled" : "Disabled"}
                            </td>
                            <td>
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="me-4 d-flex align-items-center">
                                  <Switch
                                    id={element.id}
                                    name={element.id}
                                    isChecked={element.status}
                                    disabled={toggleLoading}
                                    onChange={(e) => {
                                      toggleStatusModal();
                                      setActiveRow(element);
                                    }}
                                  />
                                </span>
                                {/* <span
                                className="me-4"
                                style={{ cursor: "pointer" }}
                                onClick={() => {}}
                              />
                              <TableActionDropdown
                                actions={tableActions}
                                horizontal={false}
                              /> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </>
              )}
            </Table>
            {!getInstanceLoading && !getInstanceSuccess?.items?.length ? (
              <EmptyState />
            ) : null}
            {getInstanceSuccess?.items?.length > 0 && (
              <div className="my-3">
                <TablePagination
                  data={getInstanceSuccess?.items}
                  totalCount={getInstanceSuccess?.count}
                  currentPage={getInstanceSuccess?.pageNumber}
                  pageSize={getInstanceSuccess?.pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  totalPages={getInstanceSuccess?.pages}
                />
              </div>
            )}
          </>
        </>
      </div>

      <ToggleStatusModal
        show={showStatusChangeModal}
        toggleModal={toggleStatusModal}
        onSubmit={toggleStatus}
        status={activeRow.status}
      />
    </>
  );
};

export default DisbursementList;
