import React, {useEffect, useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from 'reactstrap';
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import { useDispatch, useSelector} from 'react-redux';
import * as Yup from "yup";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Select from "react-select";
import CheckboxSelect from '../../../assets/images/checkboxSelect.svg';
import CheckboxFill from '../../../assets/images/checkboxFill.svg';
import CheckboxHallow from '../../../assets/images/checkboxHallow.svg';
import { getCredentials } from 'redux/actions/disbursement';
import EmptyState from 'components/emptyState/EmptyState';
import { LoadingTableContent } from 'components/loader/Loader';
import TablePagination from 'shared/tablePagination/tablePagination';
// import { errorMessage } from 'redux/actions/notification';

const Destination = ({setCurrentStep, currentStep, countries=[], currency=[], payload, setPayload}) => {
    const [selected, setSelected] = useState([]);
    const [country, setCountry] = useState(null);
    const [cur, setCur] = useState(null);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);

    const dispatch = useDispatch();

    const { destinationInstitutionLoading, destinationInstitutionPayload } = useSelector(({ disbursement }) => {
        return {
            destinationInstitutionError: disbursement?.destinationInstitutionError,
            destinationInstitutionPayload: disbursement?.destinationInstitutionSuccess,
            destinationInstitutionLoading: disbursement?.destinationInstitutionLoading,
         };
      });

      
      
    const validationSchema = Yup.object({
        applicationName: Yup.string().required("This field is required"),
      });
      const resolver = useYupValidationResolver(validationSchema);
      const {
        register,
        formState: { errors },
        control,
      } = useForm({ resolver });
      

    const selectInst = (val) =>{
        setPayload({...payload, destinationData: val});
        setPayload({...payload,destinationData: val, 
            destinationCurrency: {label: val?.currencyCode, value: val?.currencyCode}, 
            destinationCountry: {label: val?.countryName, value: val?.countryCode}})
        dispatch(getCredentials(val?.gatewayCode, ''))
    }

    

    useEffect(()=>{
        if(!destinationInstitutionLoading && destinationInstitutionPayload?.data?.message){
            // dispatch(errorMessage(destinationInstitutionPayload?.data?.message));
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationInstitutionLoading]);

    const fetchList = (curren, coun) => { 
        if(coun?.label){
            console.log({curren, coun})
          setCountry(coun?.value);
          setPayload({...payload, destinationCurrency: null, destinationCountry: coun})
          const items = destinationInstitutionPayload?.data?.responseData?.items?.filter(val => val?.countryName?.toLowerCase() === coun?.label?.toLowerCase())
          setAllData(items)
          setData(items.slice(((currentPage-1)*10), ((currentPage*10))))
          setCurrentPage(1)
        }else if(curren) {
          setCur(curren);
          if(payload?.destinationCountry){       
            const items = destinationInstitutionPayload?.data?.responseData?.items?.filter(val => val?.currencyCode?.toLowerCase() === curren?.toLowerCase() && val?.countryName?.toLowerCase() === payload?.destinationCountry?.label?.toLowerCase())
            setAllData(items)
            setData(items.slice(((currentPage-1)*10), ((currentPage*10))))
          }else{
            const items = destinationInstitutionPayload?.data?.responseData?.items?.filter(val => val?.currencyCode?.toLowerCase() === curren?.toLowerCase())
            setAllData(items)
            setData(items.slice(((currentPage-1)*10), ((currentPage*10))))
          }
        }
    }

  useEffect(()=>{
    setData(allData.slice(((currentPage-1)*10), ((currentPage*10))))
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(()=>{
     if(!destinationInstitutionLoading && destinationInstitutionPayload?.data?.responseData?.items?.length) {
      setData(destinationInstitutionPayload?.data?.responseData?.items.slice(currentPage, ((currentPage*10)+10)));
      setAllData([...destinationInstitutionPayload?.data?.responseData?.items])
     }
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationInstitutionLoading]);

    
    return (
        <>
            <div className="form-content">
                        <div className="choose">Choose your destination country and currency</div>
                    
                        <form>
                            <Row>
                                <Col>
                        <label htmlFor="status" className="form-label">Destination Country</label>
                        <Controller
                            control={control}
                            name="status"
                            id="status"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.destinationCountry}
                                onBlur={onBlur}
                                inputRef={ref}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                onChange={e => {fetchList(cur,  e)}}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [...countries?.map(val=>{
                                        return {label: val?.name, value: val?.isO3}
                                    })]
                                }
                            />
                            )}
                        />
                        </Col>
                        <Col>
                        <label htmlFor="status" className="form-label">Destination Currency</label>
                        <Controller
                            control={control}
                            name="status"
                            id="status"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                {...register("status", { required: true })}
                                value={payload?.destinationCurrency}
                                onBlur={onBlur}
                                inputRef={ref}
                                onChange={e => {fetchList(e?.label, country); setPayload({...payload, destinationCurrency: e})}}
                                controlClassName={`form-control ${errors?.status && "is-invalid"}`}
                                controlErrorMsg={errors?.status?.message}
                                components={{
                                IndicatorSeparator: () => null,
                                Control: SelectControl,
                                }}
                                options={ 
                                    [...currency?.map(val=>{
                                        return {label: val?.currencyCode, value: val?.numericCode}
                                    })]
                                }
                            />
                            )}
                        />
                        </Col>
                        </Row>
                        {/* <div className="aml-content"><input type="checkbox" id="aml"/> <label for="aml" className="aml">Use AML <img src={AmlIcon} alt="aml"/></label></div> */}
                        </form>
                    </div>
                    <div className="table-responsiveness">
                       
                       <div className="title-content">
                       {allData?.length ?<div className="choose">Select destination gateways</div>:<br/>}
                            </div>
                            {destinationInstitutionLoading || allData?.length?<table className="table">
                            <thead>
                                <tr>
                                    <td><img src={CheckboxSelect} alt="checkbox"/>  Destination Institution</td>
                                    <td>Settlement Time</td>
                                    <td>Platform Fee</td>
                                </tr>
                            </thead> 
                            <tbody>
                            {destinationInstitutionLoading?<LoadingTableContent colSpan={3}/>:null}
                            {data?.map((val, i) => {
                                    return <tr onClick={()=>{setSelected([i]); selectInst(val)}}>
                                    <td>
                                        {payload?.destinationData?.id === val?.id || selected.indexOf(i)>-1?<img src={CheckboxFill} alt="checkbox"/>:<img src={CheckboxHallow} alt="checkbox"/>}         
                                          {val?.institutionName}</td>
                                    <td>{val?.gatewayName}</td>
                                    <td>{val?.rate}</td>
                                </tr>
                                })}
                            </tbody>
                        </table>:null}
                        {allData?.length ? <TablePagination
                            totalCount={allData?.length}
                            currentPage={currentPage}
                            pageSize={10}
                            onPageChange={(page) => setCurrentPage(page)}
                            totalPages={Math.ceil(allData?.length/10)}
                        />: null}
                        {!destinationInstitutionLoading && !allData?.length ? <EmptyState message="Choose another country or currency"/>: null}
                    </div>
                    {allData?.length? <div className="btns">
                  <Button
                    label="Previous"
                    size="sm"
                    className="cancel-btn"
                    onClick={() => setCurrentStep(currentStep-1)}
                  />
                  <Button label="Next" disabled={!payload?.destinationData && selected?.length === 0} className="proceed-btn" onClick={() => setCurrentStep(currentStep+1)} />
                </div>:null}
        </>
    )
}
export default Destination;