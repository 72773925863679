import React from "react";
import "./tablePagination.scss";
import {
  PaginationWrapper,
  Pagination,
  PaginationIndicator,
} from "vgg-ui-react/dist/components/Core";

const TablePagination = ({
  currentPage,
  pageSize,
  onPageChange,
  totalCount,
  totalPages,
}) => {
  return (
    <>
      <PaginationWrapper>
        <PaginationIndicator
          currentPage={currentPage}
          totalPages={totalPages}
        />
        <div className="pagination-container">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </div>
      </PaginationWrapper>
    </>
  );
};

export default TablePagination;
