import { Get, Post, Put } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { instanceUrl } from "apiServices/config.service";
import { getUserDetails } from "apiServices/user.service";

export const Types = {
  GET_COUNTRY: "GET_COUNTRY",
  GET_CURRENCY: "GET_CURRENCY",
  SOURCE_INSTITUTION: "SOURCE_INSTITUTION",
  DESTINATION_INSTITUTION: "DESTINATION_INSTITUTION",
  GET_CREDENTIALS: "GET_CREDENTIALS",
  SUBMIT_DISBURSEMENT: "SUBMIT_DISBURSEMENT",
  GET_INSTANCES: "GET_INSTANCES",
  TOGGLE_ISENABLED: "TOGGLE_ISENABLED",
  GET_FILTERS: "GET_FILTERS",
};

export const getCountry = createAsyncAction(Types.GET_COUNTRY, async () => {
  return await Get(
    "v1/Country/GetAll?pageSize=1000&pageNumber=1&isAscending=true"
  );
});

export const getCurrency = createAsyncAction(Types.GET_CURRENCY, async () => {
  return await Get("v1/Currency/GetAll?pageSize=1000&pageNumber=1");
});

export const getSourceInstitution = createAsyncAction(
  Types.SOURCE_INSTITUTION,
  async (country, currency, size = 1000, page = 1) => {
    return await Get(
      `v1/GatewaySourceInformation/GetAll?pageSize=${size}&pageNumber=${page}`
    );
  }
);

export const getCredentials = createAsyncAction(
  Types.GET_CREDENTIALS,
  async (gatewayCode, serviceCode = "PAYOUT") => {
    return await Get(
      `v1/GatewayCredentialKey/getcredentialkey/${gatewayCode}/${serviceCode}`
    );
  }
);

export const submitDisbursement = createAsyncAction(
  Types.SUBMIT_DISBURSEMENT,
  async (data) => {
    return await Post(`v1/settings/instance`, data, instanceUrl);
  }
);

export const getDestinationInstitution = createAsyncAction(
  Types.DESTINATION_INSTITUTION,
  async (country, currency, size = 1000, page = 1) => {
    return await Get(
      `v1/GatewayDestinationInstitution/GetAll?pageSize=${size}&pageNumber=${page}`
    );
  }
);

export const getAllInstances = createAsyncAction(
  Types.GET_INSTANCES,
  async (
    pageSize,
    pageNumber,
    status,
    source,
    destination,
    keyword,
    isAscending
  ) => {
    const clientId = getUserDetails()?.user?.clientId;
    let url = `v1/settings/instance/getall?Status=${status}&isAscending=${isAscending}&ClientId=${clientId}`;
    if (pageSize) url = url + `&pageSize=${pageSize}`;
    if (pageNumber) url = url + `&pageNumber=${pageNumber}`;
    if (source) url = url + `&source=${source}`;
    if (destination) url = url + `&destination=${destination}`;
    if (keyword) url = url + `&keyword=${keyword}`;
    return await Get(url, instanceUrl);
  }
);

export const toggleIsEnabled = createAsyncAction(
  Types.TOGGLE_ISENABLED,
  async (instanceId) => {
    return await Put(
      "v1/settings/instance/toggleisenabled",
      instanceId,
      instanceUrl
    );
  }
);

export const getFilters = createAsyncAction(Types.GET_FILTERS, async () => {
  return await Get("v1/settings/instance/filters", instanceUrl);
});
