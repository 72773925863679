import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./stepper.scss";

const Stepper = ({ data, currentStepNumber, direction, stepColor, className, lastItemClass }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initialize = async() => {
    const stepsState = await data.map((step, index) => {
      const stepObj = {};
      stepObj.description = step;
      stepObj.highlighted = index === 0 ? true : false;
      stepObj.selected = index === 0 ? true : false;
      stepObj.completed = false;
      return stepObj;
    });

    const currentSteps = updateStep(currentStepNumber, stepsState);
    setSteps(currentSteps);
  };

  useEffect(() => {
    const currentSteps = updateStep(currentStepNumber, steps);
    setSteps(currentSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepNumber]);

  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let stepCounter = 0;

    while (stepCounter < newSteps.length) {
      // Current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: true,
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: true,
          completed: true,
        };
        stepCounter++;
      }
      // Future step
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }
    return newSteps;
  };

  return (
    <div className={`stepper-wrapper-${direction}`}>
      {steps.map((step, index) => {
        return (
          <div className="step-wrapper" key={index}>
            <div
              className={`step-number ${step.selected ? "step-number-selected" : "step-number-disabled"} ${className} ${index === steps.length - 1 ? lastItemClass : null}`}
              style={{ background: `${step.selected ? stepColor : "#e1e1e1"}`, borderColor: `${step.selected ? stepColor : "#aaaaaa"}` }}
            >
              {step.completed ? (
                <span data-testid="step-complete">
                  <i className="ri-check-line"></i>
                </span>
              ) : (
                <span data-testid="pending-step">{index + 1}</span>
              )}
            </div>
            <div className="step-description" style={step.highlighted ? { fontWeight: 500, color: stepColor } : {}}>
              {step.description}
            </div>
            {index !== steps.length - 1 && <div className={`divider-line divider-line-${steps.length}`} style={step.completed ? { backgroundColor: stepColor } : {}} />}
          </div>
        );
      })}
    </div>
  );
};

Stepper.defaultProps = {
  data: [],
  currentStepNumber: 1,
  stepColor: "#175fff",
  className: "",
  lastItemClass: "",
  direction: "horizontal",
};

Stepper.propTypes = {
  direction: PropTypes.string,
  currentStepNumber: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  stepColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  lastItemClass: PropTypes.string,
};

export default Stepper;
