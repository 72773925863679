import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import SourceInfo from './sourceInfo/SourceInfo'
import Name from './name/Name'
import OverDraft from './overDraftForm/OverDraftForm'
import Credentials from './credentials/Credentials'
import Review from './review/Review'
import Destination from './destination/Destination'
import Stepper from '../../components/stepper/Stepper';
import ArrowLeft from '../../assets/images/arrowLeft.svg'
import './Onboarding.scss';
import { getCountry, getCurrency, getDestinationInstitution, getSourceInstitution } from 'redux/actions/disbursement';
import URLS from 'helpers/urls';


const Onboarding =()=> {
    const dispatch = useDispatch();
    const history = useHistory();
    const stepsArray = ["Name", "Source Info", "Credentials", "Destination Info", "Review"];
    const overDraftArray = ["Name", "Overdraft", "Destination Info", "Review"];
    const [payload, setPayload] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [fundType, setFundType] = useState(1);
    
    const { getCountryPayload, getCurrencyPayload } = useSelector(({ disbursement }) => {
        return {
          getCountryError: disbursement?.getCountryError,
          getCountryPayload: disbursement?.getCountrySuccess,
          getCountryLoading: disbursement?.getCountryLoading,

          getCurrencyError: disbursement?.getCurrencyError,
          getCurrencyPayload: disbursement?.getCurrencySuccess,
          getCurrencyLoading: disbursement?.getCountryLoading,
        };
      });
     
      const onSelectFundType = (e) => {
        setCurrentStep(1);
        setFundType(e);
        setPayload(null)
      }

      useEffect(() => {
          dispatch(getCountry());
          dispatch(getCurrency());
          dispatch(getSourceInstitution())
          dispatch(getDestinationInstitution())
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    
    return (
        <Container fluid className="disbursement-onboarding-container">
            <Row>
                <Col>
                  <div className="services" onClick={()=>history.push(URLS.List)}><span>Services</span> &nbsp;&nbsp;&nbsp;/</div>
                  <div className="profile"><img src={ArrowLeft} alt="ArrowLeft" onClick={()=>history.push(URLS.List)} />Configure Profile</div>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="items-content">
                    <div className={`items ${fundType === 1 && 'items-active'}`} onClick={()=>onSelectFundType(1)}>
                        <div className="sqr">&nbsp;</div>
                        <div>
                            <div className="name">Pre-funding</div>
                            <div className="desc">Specify source currency and destination currency.</div>
                        </div>
                    </div>
                    <div className={`items ${fundType === 2 && 'items-active'}`} onClick={()=>onSelectFundType(2)}>
                        <div className="sqr">&nbsp;</div>
                        <div>
                            <div className="name">Post-funding</div>
                            <div className="desc">Specify source currency and destination currency.</div>
                        </div>
                    </div>
                </Col>
                <Col md={8} className="data-content">
                    {fundType ===1? <>
                        <div>
                        <Stepper direction="horizontal" currentStepNumber={currentStep - 1} data={stepsArray} lastItemClass={currentStep === stepsArray.length ? "awaiting-verify" : ""} />
                        </div>
                        {currentStep === 1 && <Name setCurrentStep={setCurrentStep} currentStep={currentStep} setPayload={setPayload} payload={payload}/>}
                        {currentStep === 2 && <SourceInfo setCurrentStep={setCurrentStep} currentStep={currentStep} countries={getCountryPayload?.data?.responseData?.items||[]} currency={getCurrencyPayload?.data?.responseData?.items||[]} setPayload={setPayload} payload={payload}/>}
                        {currentStep === 3 && <Credentials setCurrentStep={setCurrentStep} currentStep={currentStep} setPayload={setPayload} payload={payload} currency={getCurrencyPayload?.data?.responseData?.items||[]}/>}
                        {currentStep === 4 && <Destination setCurrentStep={setCurrentStep} currentStep={currentStep} countries={getCountryPayload?.data?.responseData?.items||[]} currency={getCurrencyPayload?.data?.responseData?.items||[]} setPayload={setPayload} payload={payload}/>}
                        {currentStep === 5 && <Review setCurrentStep={setCurrentStep} currentStep={currentStep} setPayload={setPayload} payload={payload}/> }
                    </>:<>
                        <div>
                        <Stepper direction="horizontal" currentStepNumber={currentStep - 1} data={overDraftArray} lastItemClass={currentStep === overDraftArray.length ? "awaiting-verify" : ""} />
                        </div>
                        {currentStep === 1 && <Name setCurrentStep={setCurrentStep} currentStep={currentStep} setPayload={setPayload} payload={payload}/>}
                        {currentStep === 2 && <OverDraft setCurrentStep={setCurrentStep} currentStep={currentStep} countries={getCountryPayload?.data?.responseData?.items||[]} currency={getCurrencyPayload?.data?.responseData?.items||[]} setPayload={setPayload} payload={payload}/>}
                        {currentStep === 3 && <Destination setCurrentStep={setCurrentStep} currentStep={currentStep} countries={getCountryPayload?.data?.responseData?.items||[]} currency={getCurrencyPayload?.data?.responseData?.items||[]} setPayload={setPayload} payload={payload}/>}
                        {currentStep === 4 && <Review setCurrentStep={setCurrentStep} currentStep={currentStep} setPayload={setPayload} payload={payload}/> }
                    </>}
                </Col>
            </Row>
        </Container>
    )
}

export default Onboarding;